// import classes
import { Depth, depth_i } from '@/models/data_context/depth';
import { DateRange, date_range_i } from '@/models/data_context/date_range';

// import interfaces
import moment, { unitOfTime } from 'moment-timezone';

export abstract class Client {
  code: number;
  name: string;
  isAdmin: number;
  can_export_data: boolean;
  can_use_api: boolean;
  rights: Record<string, Array<number>>;
  granularities: Array<number>;
  removed_observations: Array<number> = [];

  constructor(
    code: number,
    name: string,
    isAdmin: number,
    can_export_data: boolean,
    can_use_api: boolean,
    rights: Record<string, Array<number>>,
    granularities: Array<number>,
    removed_observations: Array<number> = []
  ) {
    this.code = code;
    this.name = name;
    this.isAdmin = isAdmin;
    this.can_export_data = can_export_data;
    this.can_use_api = can_use_api;
    this.rights = rights;
    this.granularities = granularities;
    this.removed_observations = removed_observations;
  }
}

export class ClientDepth extends Client {
  depth: Record<string, Depth>;

  constructor(
    code: number,
    name: string,
    isAdmin: number,
    can_export_data: boolean,
    can_use_api: boolean,
    rights: Record<string, Array<number>>,
    granularities: Array<number>,
    removed_observations: Array<number>,
    depth: Record<string, { number: number; unit: string; }>
  ) {
    super(code, name, isAdmin, can_export_data, can_use_api, rights, granularities, removed_observations);

    this.depth = {};
    Object.keys(depth).forEach(key => {
      this.depth[key] = new Depth(
        depth[key].number,
        depth[key].unit as unitOfTime.DurationConstructor
      );
    });
  }
}

export class ClientPeriod extends Client {
  period: Record<string, DateRange>;

  constructor(
    code: number,
    name: string,
    isAdmin: number,
    can_export_data: boolean,
    can_use_api: boolean,
    rights: Record<string, Array<number>>,
    granularities: Array<number>,
    removed_observations: Array<number>,
    period: Record<string, { from: string; to: string; }>
  ) {
    super(code, name, isAdmin, can_export_data, can_use_api, rights, granularities, removed_observations);

    this.period = {};
    Object.keys(period).forEach(key => {
      this.period[key] = new DateRange(
        moment(period[key].from),
        moment(period[key].to)
      );
    });
  }
}

export interface client_i {
  code: number;
  name: string;
  isAdmin: number;
  can_export_data: boolean;
  can_use_api: boolean;
  rights: Record<string, Array<number>>;
  granularities: Array<number>;
  removed_observations: Array<number>;
  depth?: Record<string, depth_i>;
  period?: Record<string, date_range_i>;
}
